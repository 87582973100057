<template>
  <div>
    <v-row class="pt-12" align="center" justify="center">
      <v-col cols="6">
        <h4 class="text-center text-h4">Order Summary</h4>
      </v-col>
    </v-row>
    <v-row class="pt-4" align="center" justify="center">
      <v-col cols="6" class="d-flex flex-column justify-center align-center">
        <p class="font-weight-bold">
          {{ order.client.fullName }}, {{ order.client.title }},
          {{ order.client.organization }}
        </p>
        <p class="ma-0">{{ order.client.email }}</p>
        <p>{{ order.client.phone }}</p>
      </v-col>
    </v-row>
    <v-row class="pt-0" align="center" justify="center">
      <v-col cols="6" class="d-flex flex-column justify-center align-center">
        <p>
          <strong class="mr-2">Project:</strong>
          <span class="mr-4"
            >{{ order.projectTitle }} ({{ order.projectType }} - {{ order.orderFor }})</span
          >
          <strong
            class="mr-2"
            v-if="
              order.client.accountCode && order.client.existingAccount === 'Yes'
            "
            >Customer Code:</strong
          >
          <span
            class="mr-4"
            v-if="
              order.client.accountCode && order.client.existingAccount === 'Yes'
            "
            >{{ order.client.accountCode }}</span
          >
          <strong class="mr-2">Delivery:</strong
          ><span>{{ order.delivery.type }}</span>
        </p>
        <p>
          <strong class="mr-2">Shoot Dates:</strong>
          <span
            v-for="(item, i) in computedShootDateFormatted"
            v-bind:key="i"
            class="mr-2"
            >{{ item }}</span
          >

          <strong class="mr-2">Drop Date:</strong
          ><span>{{ computedDropDateFormatted }}</span>
        </p>
      </v-col>
    </v-row>
    <v-row
      v-if="
        order.orderType === 'Processing & Scanning' ||
        order.orderType === 'Processing Only'
      "
      class="pt-6"
      align="center"
      justify="center"
    >
      <v-col cols="6" class="d-flex flex-column justify-center align-center">
        <p class="text-h5 font-weight-bold">Processing</p>
      </v-col>
    </v-row>
    <div
      v-if="
        order.orderType === 'Processing & Scanning' ||
        order.orderType === 'Processing Only'
      "
      class=""
    >
      <div v-for="(item, index) in order.film" v-bind:key="index">
        <v-row
          v-if="item.processing.isNeeded"
          class="pb-2"
          align="center"
          justify="center"
        >
          <v-col cols="6" class="pt-1 pb-1 d-flex align-center justify-center">
            <span
              >{{ item.filmFormat }} / {{ item.colorOrBW }} /
              {{ item.reversalOrNegative }} / {{ item.totalFootage }}
            </span>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row
      v-if="
        order.orderType === 'Processing & Scanning' ||
        order.orderType === 'Scanning Only'
      "
      class="pt-6"
      align="center"
      justify="center"
    >
      <v-col cols="6" class="d-flex flex-column justify-center align-center">
        <p class="text-h5 font-weight-bold">Scanning</p>
      </v-col>
    </v-row>
    <div
      v-if="
        order.orderType === 'Processing & Scanning' ||
        order.orderType === 'Scanning Only'
      "
      class=""
    >
      <v-row
        v-if="order.scanning.isNeeded"
        class="pb-2"
        align="center"
        justify="center"
      >
        <v-col cols="12" class="pt-1 pb-1 d-flex align-center justify-center">
          <span
            >{{ order.scanning.resolution }} /
            {{ order.scanning.gateAperture }} /
            {{ order.scanning.frameRate }}
          </span>
          <span>{{ order.scanning.summary }} </span>
        </v-col>
        <v-col
          v-if="order.scanning && order.scanning.editorialMedia"
          cols="12"
          class="d-flex align-center justify-center"
        >
          <em>With Digital Media</em>
        </v-col>
        <v-col
          v-if="order.scanning && order.scanning.editorialMedia"
          cols="12"
          class="mb-0 d-flex align-center justify-center"
        >
          {{ order.scanning.editorialMedia }}
        </v-col>
        <v-col cols="12" class="mb-4 d-flex align-center justify-center">
          <span
            v-if="
              order.scanning.editorialMedia === 'Uncompressed only' ||
              order.scanning.editorialMedia === 'Uncompressed & Editorial'
            "
          >
            {{ order.scanning.uncompressedMediaCodec }}&nbsp;</span
          >
        </v-col>
        <v-col cols="12" class="mb-4 d-flex align-center justify-center">
          <span
            v-if="
              order.scanning.editorialMedia === 'Editorial only' ||
              order.scanning.editorialMedia === 'Uncompressed & Editorial'
            "
          >
            {{ order.scanning.editorialMediaResolution }}&nbsp;</span
          ><span
            v-if="
              order.scanning.editorialMedia === 'Editorial only' ||
              order.scanning.editorialMedia === 'Uncompressed & Editorial'
            "
          >
            / {{ order.scanning.editorialMediaCodec }}&nbsp;</span
          ><span
            v-if="
              order.scanning.editorialMedia === 'Editorial only' ||
              order.scanning.editorialMedia === 'Uncompressed & Editorial'
            "
          >
            / {{ order.scanning.editorialMediaColor }}</span
          >
        </v-col>
      </v-row>
    </div>

    <v-row class="pt-12" align="center" justify="center">
      <v-col cols="6" class="pb-1 d-flex justify-center align-center">
        <p>Additional Project Notes</p>
      </v-col>
    </v-row>
    <v-row class="pt-0" align="center" justify="center">
      <v-col cols="4" class="pt-0 d-flex">
        <v-textarea
          outlined
          v-model="project.additionalProjectNotes"
          placeholder="None"
          @blur="onChange"
        ></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ProjectAbout',
  props: {
    order: Object
  },
  data: function () {
    return {
      project: {
        additionalProjectNotes: this.order.additionalProjectNotes || null
      }
    }
  },
  computed: {
    computedDropDateFormatted () {
      return this.formatDate(this.order.dropDate)
    },
    computedShootDateFormatted () {
      return this.order.shootDates.map((element) => {
        return this.formatDate(element)
      })
      // return this.formatDate(date)
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    onChange (event) {
      this.$emit('onChange', {
        project: { ...this.order, ...this.project }
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
