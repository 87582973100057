import axios from 'axios'
// import router from '@/router'

export default () => {
  const instance = axios.create({
    baseURL: 'https://fotokem.com/api/v1', // process.env.VUE_APP_SERVER_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })

  return instance
}
