<template>
  <v-container fluid class="pb-8" id="page">
    <Summary
      v-if="getOrder.orderType"
      :order="getOrder"
      @onChange="onChange($event)"
    />
    <div v-if="result && result.errors" class="error-result">
      {{ result.message }}
    </div>
    <v-row class="pt-4" align="center" justify="center">
      <v-col cols="6" class="d-flex justify-start">
        <v-row>
          <v-col cols="6" class="d-flex justify-start">
            <v-btn right plain outlined @click="startOver"> Start Over </v-btn>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <v-btn
              class="mr-4"
              :disabled="loading || !getOrder.orderType"
              left
              plain
              outlined
              @click="nextStep(5)"
            >
              Back
            </v-btn>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading || !getOrder.orderType"
              right
              plain
              outlined
              @click="onConfirm"
            >
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Api from '@/services/api'
import Summary from '@/components/Summary'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'summary',
  title: 'Summary',
  components: {
    Summary
  },
  props: {},
  data: function () {
    return {
      loading: false,
      currentSection: 'start',
      order: null,
      subject: null,
      result: null,
      ip: 'unknown',
      userAgent: 'unkown'
    }
  },
  computed: {
    ...mapState('snackbar', {
      snackbars: (state) => state.snackbars
    }),
    ...mapGetters({
      getOrder: 'order/getOrder'
    }),
    message () {
      let account = `<strong>Existing Account: </strong>${this.getOrder.client.existingAccount}<br>`

      if (this.getOrder.client.existingAccount === 'Yes') {
        account =
          account +
          `<strong>Account Code: </strong>${this.getOrder.client.accountCode}<br>`
      }

      let contactMethod = '<strong>Contact Method:</strong><br>'

      if (this.getOrder.client.contactCall) {
        contactMethod = contactMethod + 'Call<br>'
      }
      if (this.getOrder.client.contactText) {
        contactMethod = contactMethod + 'Text<br>'
      }
      if (this.getOrder.client.contactEmail) {
        contactMethod = contactMethod + 'Email<br>'
      }

      let filmString = ''
      this.order.film.forEach((element) => {
        filmString =
          filmString + `<strong>Format:</strong> ${element.filmFormat}<br>`
        filmString =
          filmString +
          `<strong>Type:</strong> ${element.colorOrBW} ${element.reversalOrNegative}<br>`
        filmString =
          filmString + `<strong>Footage:</strong> ${element.totalFootage}<br>`
        filmString =
          filmString + `<strong>Rolls:</strong> ${element.numRolls}<br>`
        let instructions = '<br>'
        if (element.processing.additionalProcessingInstructions) {
          instructions = element.processing.additionalProcessingInstructions
        }
        filmString =
          filmString +
          `<strong>Processing:</strong> ${element.processing.processingInstructions} ${instructions}<br><br>`
      })

      let scanningInstructions = ''
      if (this.getOrder.scanning.additionalScanningInstructions) {
        scanningInstructions = `<strong>Additional Instructions:</strong> ${this.getOrder.scanning.additionalScanningInstructions}<br>`
      }

      let media = `<br><strong>Media:</strong> ${this.getOrder.scanning.editorialMedia}<br>`
      if (this.getOrder.scanning.editorialMedia !== 'Uncompressed only') {
        media =
          media +
          `<strong>Editorial Media Resolution:</strong> ${this.getOrder.scanning.editorialMediaResolution}<br>`
        media =
          media +
          `<strong>Editorial CODEC:</strong> ${this.getOrder.scanning.editorialMediaCodec}<br>`
        media =
          media +
          `<strong>Editorial Media Color:</strong> ${this.getOrder.scanning.editorialMediaColor}<br><br>`
      }
      let umedia = ''
      if (this.getOrder.scanning.editorialMedia !== 'Editorial only') {
        if (this.getOrder.scanning.uncompressedMediaResolution) {
          umedia =
            umedia +
            `<strong>Uncompressed Media Resolution:</strong> ${this.getOrder.scanning.uncompressedMediaResolution}<br>`
        }
        if (this.getOrder.scanning.uncompressedMediaCodec) {
          umedia =
            umedia +
            `<strong>Uncompressed CODEC:</strong> ${this.getOrder.scanning.uncompressedMediaCodec}<br>`
        }
        if (this.getOrder.scanning.uncompressedMediaColor) {
          umedia =
            umedia +
            `<strong>Uncompressed Media Color:</strong> ${this.getOrder.scanning.uncompressedMediaColor}<br><br>`
        }
      }
      let ScanningString = ''

      if (this.getOrder.scanning.isNeeded) {
        ScanningString =
          ScanningString +
          `<strong>Rsolution:</strong> ${this.getOrder.scanning.resolution}<br><strong>Gate Aperture:</strong> ${this.getOrder.scanning.gateAperture}<br><strong>Film Rate:</strong> ${this.getOrder.scanning.frameRate}<br>${scanningInstructions}${media}${umedia}<br>`
      }
      let delMethod = ''
      if (this.getOrder.delivery.type === 'Hard Drive') {
        delMethod =
          delMethod +
          `<br><strong>Format:</strong> ${this.getOrder.delivery.format}`
        if (this.getOrder.delivery.format === 'Other') {
          delMethod = delMethod + ` - ${this.getOrder.delivery.formatOther}`
        }
        delMethod =
          delMethod +
          `<br><strong>Back-up:</strong> ${this.getOrder.delivery.backup}<br>`
        if (this.getOrder.delivery.backupCount) {
          delMethod =
            delMethod +
            `<strong>Number of Back-ups:</strong> ${this.getOrder.delivery.backupCount}<br>`
        }
      }
      if (this.getOrder.delivery.type === 'Digital') {
        delMethod =
          delMethod +
          `<br><strong>Method:</strong> ${this.getOrder.delivery.digitalMethod}<br>`

        if (this.getOrder.delivery.digitalMethod === 'globalDATA') {
          delMethod =
            delMethod +
            `<strong>Account Info:</strong> ${this.getOrder.delivery.digitalMethodDetails}<br>`
        }
        if (this.getOrder.delivery.digitalMethod === 'Other') {
          delMethod =
            delMethod +
            `<strong>Details:</strong> ${this.getOrder.delivery.digitalMethodDetails}<br>`
        }
      }
      if (this.getOrder.delivery.additionalNotes) {
        delMethod =
          delMethod +
          `<strong>Additional Delivery Instructions:</strong> <br>${this.getOrder.delivery.additionalNotes}<br>`
      }

      let notes = ''
      if (this.getOrder.additionalProjectNotes) {
        notes = `<strong>Additional Project Notes:</strong> <br>${this.getOrder.additionalProjectNotes}<br>`
      }
      const message = `<strong>Order Type:</strong> ${this.getOrder.orderType} - ${this.getOrder.orderFor}<br><br>
      <strong>Project:</strong><br><br>
      <strong>Title:</strong> (${this.getOrder.client.fullName}) ${this.getOrder.projectTitle}<br>
      <strong>Type:</strong> ${this.getOrder.projectType}<br>
      <strong>Shoot Location:</strong> ${this.getOrder.shootLocation}<br>
      <strong>Shoot Dates:</strong> ${this.computedShootDateFormatted}<br>
      <strong>Drop Date:</strong> ${this.computedDropDateFormatted}<br><br><br>
      <strong>Film:</strong><br><br>
      ${filmString}
      <strong>Scanning:</strong><br><br>
      ${ScanningString}
      <strong>Client:</strong><br><br>
      <strong>Name:</strong> ${this.getOrder.client.fullName}<br>
      <strong>Title:</strong> ${this.getOrder.client.title}<br>
      <strong>Organization:</strong> ${this.getOrder.client.organization}<br>
      <strong>Phone:</strong> ${this.getOrder.client.phone}<br>
      <strong>Email:</strong> ${this.getOrder.client.email}<br>
      ${contactMethod}<br><br>
      ${account}<br><br>
      <strong>Delivery:</strong> ${this.getOrder.delivery.type}<br>
      ${delMethod}<br><br>
      ${notes}<br><br><br><br>
      Sender Data:<br>IP - ${this.ip}<br>User Agent - ${this.userAgent}
      `
      return message
    },
    computedDropDateFormatted () {
      return this.formatDate(this.order.dropDate)
    },
    computedShootDateFormatted () {
      const dates = this.order.shootDates.map((element) => {
        return this.formatDate(element)
      })
      let dateSting = ''
      dates.forEach((element) => {
        dateSting = dateSting + ' ' + element
      })
      return dateSting
    }
  },
  async created () {
    this.checkParams()
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    startOver () {
      this.$emit('startOver')
    },
    onChange (event) {
      this.$emit('onChangeProjectNotes', {
        order: event.project
      })
    },
    showSummary (event) {
      this.currentSection = event.section
    },
    async onConfirm () {
      this.loading = true
      this.userAgent = window.navigator.userAgent
      await this.$http
        .get('https://api.ipify.org?format=json')
        .then((result) => {
          this.ip = result.data.ip
        })
      try {
        const { data: response } = await Api()
          .post('https://api.fotokem.com/api/v1/send/message/dailiesorder', {
            message: this.message,
            subject: 'Dailies Services Web Order - ' + this.getOrder.client.fullName + ' - ' + this.getOrder.projectTitle
          })
        this.result = response
        if (!this.result.errors) {
          this.$emit('clearOrder')
          this.nextStep(7)
        }
        this.loading = false
      } catch (err) {

      }
    },
    nextStep (n) {
      this.$emit('nextStep', {
        n: n
      })
    },
    async checkParams () {
      this.order = this.getOrder
    }
  }
}
</script>
<style lang="scss">
 .error-result {
  font-size: 18px;
    font-weight: bold;
    width: 25%;
    border-radius: 6px;
    margin: 0 auto;
    padding: 24px;
    text-align: center;
    color: #fff;
    background-color: #ff5252 !important;
    border-color: #ff5252 !important;
 }
</style>
