var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"pt-12",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('h4',{staticClass:"text-center text-h4"},[_vm._v("Order Summary")])])],1),_c('v-row',{staticClass:"pt-4",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"d-flex flex-column justify-center align-center",attrs:{"cols":"6"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.order.client.fullName)+", "+_vm._s(_vm.order.client.title)+", "+_vm._s(_vm.order.client.organization)+" ")]),_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.order.client.email))]),_c('p',[_vm._v(_vm._s(_vm.order.client.phone))])])],1),_c('v-row',{staticClass:"pt-0",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"d-flex flex-column justify-center align-center",attrs:{"cols":"6"}},[_c('p',[_c('strong',{staticClass:"mr-2"},[_vm._v("Project:")]),_c('span',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.order.projectTitle)+" ("+_vm._s(_vm.order.projectType)+" - "+_vm._s(_vm.order.orderFor)+")")]),(
            _vm.order.client.accountCode && _vm.order.client.existingAccount === 'Yes'
          )?_c('strong',{staticClass:"mr-2"},[_vm._v("Customer Code:")]):_vm._e(),(
            _vm.order.client.accountCode && _vm.order.client.existingAccount === 'Yes'
          )?_c('span',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.order.client.accountCode))]):_vm._e(),_c('strong',{staticClass:"mr-2"},[_vm._v("Delivery:")]),_c('span',[_vm._v(_vm._s(_vm.order.delivery.type))])]),_c('p',[_c('strong',{staticClass:"mr-2"},[_vm._v("Shoot Dates:")]),_vm._l((_vm.computedShootDateFormatted),function(item,i){return _c('span',{key:i,staticClass:"mr-2"},[_vm._v(_vm._s(item))])}),_c('strong',{staticClass:"mr-2"},[_vm._v("Drop Date:")]),_c('span',[_vm._v(_vm._s(_vm.computedDropDateFormatted))])],2)])],1),(
      _vm.order.orderType === 'Processing & Scanning' ||
      _vm.order.orderType === 'Processing Only'
    )?_c('v-row',{staticClass:"pt-6",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"d-flex flex-column justify-center align-center",attrs:{"cols":"6"}},[_c('p',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Processing")])])],1):_vm._e(),(
      _vm.order.orderType === 'Processing & Scanning' ||
      _vm.order.orderType === 'Processing Only'
    )?_c('div',{},_vm._l((_vm.order.film),function(item,index){return _c('div',{key:index},[(item.processing.isNeeded)?_c('v-row',{staticClass:"pb-2",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"pt-1 pb-1 d-flex align-center justify-center",attrs:{"cols":"6"}},[_c('span',[_vm._v(_vm._s(item.filmFormat)+" / "+_vm._s(item.colorOrBW)+" / "+_vm._s(item.reversalOrNegative)+" / "+_vm._s(item.totalFootage)+" ")])])],1):_vm._e()],1)}),0):_vm._e(),(
      _vm.order.orderType === 'Processing & Scanning' ||
      _vm.order.orderType === 'Scanning Only'
    )?_c('v-row',{staticClass:"pt-6",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"d-flex flex-column justify-center align-center",attrs:{"cols":"6"}},[_c('p',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Scanning")])])],1):_vm._e(),(
      _vm.order.orderType === 'Processing & Scanning' ||
      _vm.order.orderType === 'Scanning Only'
    )?_c('div',{},[(_vm.order.scanning.isNeeded)?_c('v-row',{staticClass:"pb-2",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"pt-1 pb-1 d-flex align-center justify-center",attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.order.scanning.resolution)+" / "+_vm._s(_vm.order.scanning.gateAperture)+" / "+_vm._s(_vm.order.scanning.frameRate)+" ")]),_c('span',[_vm._v(_vm._s(_vm.order.scanning.summary)+" ")])]),(_vm.order.scanning && _vm.order.scanning.editorialMedia)?_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12"}},[_c('em',[_vm._v("With Digital Media")])]):_vm._e(),(_vm.order.scanning && _vm.order.scanning.editorialMedia)?_c('v-col',{staticClass:"mb-0 d-flex align-center justify-center",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.order.scanning.editorialMedia)+" ")]):_vm._e(),_c('v-col',{staticClass:"mb-4 d-flex align-center justify-center",attrs:{"cols":"12"}},[(
            _vm.order.scanning.editorialMedia === 'Uncompressed only' ||
            _vm.order.scanning.editorialMedia === 'Uncompressed & Editorial'
          )?_c('span',[_vm._v(" "+_vm._s(_vm.order.scanning.uncompressedMediaCodec)+" ")]):_vm._e()]),_c('v-col',{staticClass:"mb-4 d-flex align-center justify-center",attrs:{"cols":"12"}},[(
            _vm.order.scanning.editorialMedia === 'Editorial only' ||
            _vm.order.scanning.editorialMedia === 'Uncompressed & Editorial'
          )?_c('span',[_vm._v(" "+_vm._s(_vm.order.scanning.editorialMediaResolution)+" ")]):_vm._e(),(
            _vm.order.scanning.editorialMedia === 'Editorial only' ||
            _vm.order.scanning.editorialMedia === 'Uncompressed & Editorial'
          )?_c('span',[_vm._v(" / "+_vm._s(_vm.order.scanning.editorialMediaCodec)+" ")]):_vm._e(),(
            _vm.order.scanning.editorialMedia === 'Editorial only' ||
            _vm.order.scanning.editorialMedia === 'Uncompressed & Editorial'
          )?_c('span',[_vm._v(" / "+_vm._s(_vm.order.scanning.editorialMediaColor))]):_vm._e()])],1):_vm._e()],1):_vm._e(),_c('v-row',{staticClass:"pt-12",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"pb-1 d-flex justify-center align-center",attrs:{"cols":"6"}},[_c('p',[_vm._v("Additional Project Notes")])])],1),_c('v-row',{staticClass:"pt-0",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"pt-0 d-flex",attrs:{"cols":"4"}},[_c('v-textarea',{attrs:{"outlined":"","placeholder":"None"},on:{"blur":_vm.onChange},model:{value:(_vm.project.additionalProjectNotes),callback:function ($$v) {_vm.$set(_vm.project, "additionalProjectNotes", $$v)},expression:"project.additionalProjectNotes"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }